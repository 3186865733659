import Modal from "./bootstrap/modal";

window.addEventListener(
  "message",
  function (event) {
    if (event.data.type == "form-submit") {
      const myModal = new Modal(document.getElementById("step3"));
      this.document.body.style.overflow = "";
      myModal.toggle();
    }
  },
  false
);
